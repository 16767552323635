/*1600 * 900*/
.auto169 {
    width: 800px;
    height: 450px;
}

.auto916 {
    width: 450px;
    height: 800px;
}

/*1600 * 1200*/
.auto43 {
    width: 800px;
    height: 600px;
}

.auto34 {
    width: 600px;
    height: 800px;
}

/*1200 * 1200*/
.auto11 {
    width: 600px;
    height: 600px;
}

/*900 * 450 -> 800 * 400  1.125*/
.medium {
    width: 800px;
    height: 400px;
}

.hashnode {
    width: 800px;
    height: 420px;
}

/*3000 * 1055 -> 800 * 281  3.75*/
.tumblr_header {
    width: 800px;
    height: 281px;
}

.tumblr_post {
    width: 500px;
    height: 750px;
}

/*1500 * 600 -> 750 * 300  2*/
.notion_desktop {
    width: 750px;
    height: 300px;
}

/*1170 * 230 ->  800 * 157    1.4625*/
.notion_mobile {
    width: 800px;
    height: 157px;
}

/*940 * 726 -> 800 * 618   1.175*/
.wix {
    width: 800px;
    height: 618px;
}

/*1920 * 1080 -> 800 * 450  2.4 */
.tiktok_hor {
    width: 800px;
    height: 450px;
}

/*1080 * 1920 -> 450 * 800 2.4*/
.tiktok_ver {
    width: 450px;
    height: 800px;
}

/*1280 * 720 -> 800 * 450  1.6 */
.youtube_hor {
    width: 800px;
    height: 450px;
}

/*720 * 1080 -> 450 * 800  1.6 */
.youtube_ver {
    width: 450px;
    height: 800px;
}

.facebook {
    width: 851px;
    height: 315px;
}

/*1500 * 500 -> 800 * 267  1.875*/
.twitter1 {
    width: 800px;
    height: 267px;
}

/*1024 * 512  -> 800 * 400  1.28*/
.twitter2 {
    width: 1024px;
    height: 512px;
}

/*900 * 383  -> 800 * 340.4    1.125*/
.wechat {
    width: 800px;
    height: 340px;
}

.red_cover_hor {
    width: 800px;
    height: 600px;
}

/*1242 * 1660 -> 800 * 1069   1.5525*/
.red_cover_ver {
    width: 1242px;
    height: 1660px;
}

/*1200 * 900 -> 800 * 600  1.5*/
.red_image_hor {
    width: 1200px;
    height: 900px;
}

/*900 * 1200 -> 600 * 800  1.5*/
.red_image_ver {
    width: 600px;
    height: 800px;
}

/*1146 * 717 -> 800 * 500  1.4325*/
.bilibili {
    width: 1146px;
    height: 717px;
}

/*1380 * 560 -> 800 * 325  1.725*/
.zhihu {
    width: 1380px;
    height: 560px;
}

/*.typora {*/
/*    */
/*}*/

.dev {
    height: 340px;
    width: 860px;
}


